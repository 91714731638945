import { isEmpty } from 'lodash';
import { InfiniteData, useMutation, useQueryClient } from 'react-query';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';

import { WorkspaceListResponse } from '../types';

export const useWorkspaceAction = () => {
  const client = createHttpClient();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({
      clientId,
      isActive,
    }: {
      clientId: number;
      onSuccess: () => void;
      onError: () => void;
      isActive: boolean;
      searchString: string;
    }) =>
      client({
        url: isActive
          ? END_POINTS.WORKSPACE.DEACTIVATE_CLIENT(clientId)
          : END_POINTS.WORKSPACE.ACTIVATE_CLIENT(clientId),
        method: HttpMethod.POST,
      }),
    {
      onError: (error: Error, variables) => {
        variables?.onError?.();
        showAlert({
          type: AlertType.ERROR,
          header: `Failed to ${variables.isActive ? 'deactivate' : 'activate'} workspace`,
          message: `${error?.message || 'Request failed'}`,
        });
      },
      onSuccess: (_, variables) => {
        const cache = queryClient.getQueryData<
          InfiniteData<WorkspaceListResponse>
        >([QUERY_KEYS.WORKSPACE.WORKSPACE_LIST, variables.searchString]);

        variables?.onSuccess?.();

        if (isEmpty(cache?.pages)) {
          return;
        }

        const updatedPages = cache?.pages?.map(page => {
          const updatedItems = page.items.map(item => {
            if (item.id === variables.clientId) {
              return {
                ...item,
                isActive: !variables.isActive,
              };
            }

            return item;
          });

          return { ...page, items: updatedItems };
        });
        queryClient.setQueryData<InfiniteData<WorkspaceListResponse>>(
          QUERY_KEYS.WORKSPACE.WORKSPACE_LIST,
          {
            ...cache,
            pages: updatedPages,
          },
        );
        showAlert({
          type: AlertType.SUCCESS,
          message: `Workspace ${variables.isActive ? 'deactivated' : 'activated'} successfully!`,
        });
      },
    },
  );

  return mutation;
};
