import { useRef, useState } from 'react';

import { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { AngleBack } from '@assets/icons';
import { Input, PermissionAccess, Spinner } from '@components/index';
import { GENERAL_CONSTANTS } from '@constants/gereralConstants';
import { UserPermissions } from '@enums/user-permissions';
import RoutesPath from '@routes/constants';
import { isSubscriptionAdmin } from '@store/atoms/userPermissions';

import WorkspaceTable from './components/WorkspaceTable';
import { useListWorkspace } from './hooks/use-list-workspace';

const Workspace = () => {
  const isSubscriptionAdminUser = useRecoilValue(isSubscriptionAdmin);

  const [workspaceSearchKeyword, setWorkspaceSearchKeyword] =
    useState<string>('');

  const handleWorkspaceSearch = useRef(
    debounce(setWorkspaceSearchKeyword, 500),
  ).current;

  const { workspaceList, isLoading } = useListWorkspace({
    searchKeyword: workspaceSearchKeyword,
  });

  return (
    <PermissionAccess
      permission={UserPermissions.VIEW_WORKSPACES}
      AllowedContent={
        <div className="w-full flex flex-col px-6">
          <div className="flex flex-row justify-between gap-2 items-center w-full h-[122px] py-12 px-11">
            <div className="flex items-center gap-2">
              <Link to={RoutesPath.BRAND_LIBRARY}>
                <AngleBack className="cursor-pointer" />
              </Link>
              <div className="text-2xl font-semibold">
                {GENERAL_CONSTANTS.Workspaces}
              </div>
            </div>
            <div>
              <Input
                placeholder="Search workspace"
                customInputClass="!w-[300px] !border-gray-6"
                withSearchIcon
                onChange={e => {
                  handleWorkspaceSearch(e.target.value.trim());
                }}
              />
            </div>
          </div>
          {isLoading ? (
            <div className="relative pb-2 h-10">
              <div className="absolute inset-0 flex flex-col items-center justify-center">
                <Spinner />
              </div>
            </div>
          ) : (
            <WorkspaceTable
              workspaces={workspaceList}
              searchString={workspaceSearchKeyword}
            />
          )}
        </div>
      }
      redirectIfNoAccess
      forceRedirectToNoAccess={!isSubscriptionAdminUser}
    />
  );
};

export default Workspace;
