import { isEmpty } from 'lodash';
import { useInfiniteQuery } from 'react-query';

import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';

import { SubscriptionClient, WorkspaceListResponse } from '../types';

export const useListWorkspace = ({
  searchKeyword,
}: {
  searchKeyword?: string;
}) => {
  const client = createHttpClient();
  const queryKey = [QUERY_KEYS.WORKSPACE.WORKSPACE_LIST, searchKeyword];

  const query = useInfiniteQuery(
    queryKey,
    ({ pageParam = 'firstPage' }) => {
      const pagination =
        pageParam === 'firstPage' ? { limit: 1000 } : { next: pageParam };

      return client<WorkspaceListResponse>({
        url: END_POINTS.WORKSPACE.GET_ALL_WORKSPACE,
        params: {
          ...pagination,
          ...(searchKeyword && { searchString: searchKeyword }),
        },
      });
    },
    {
      getNextPageParam: lastPage =>
        lastPage.cursor?.hasNext ? lastPage.cursor?.next : undefined,
    },
  );
  const { data } = query;
  const workspaceList = (data?.pages ?? []).reduce(
    (allWorkspaces: SubscriptionClient[], asset) => {
      if (!isEmpty(asset.items)) {
        allWorkspaces.push(...asset.items);
      }

      return allWorkspaces;
    },
    [],
  );

  return { ...query, workspaceList };
};
